import React from 'react';
import { Box, Text } from 'rebass';
import { Styled } from 'theme-ui';

import { Section } from '../section';

export const AppSection = () => {
  return (
    <Section background='#FFFFFF' id='app'>
      <Box sx={{ paddingTop: 5 }}>
        <Box>
          <Text
            id='app-anchor'
            sx={{
              color: 'color-primary-500',
              textAlign: 'center',
              fontSize: 5,
              fontWeight: 600,
            }}>
            Your favorite recipes all in one place
          </Text>
          <Text
            sx={{ color: 'color-basic-600', textAlign: 'center', fontSize: 3 }}>
            It's an easy way to organise tried and tested recipes from{' '}
            <Text as='span' sx={{ fontWeight: 600 }}>
              ALL
            </Text>{' '}
            sources. 
          </Text>
          <Text mt={4} sx={{ fontFamily: 'secondary', fontSize: 2 }}>
            Have you ever wondered how...
          </Text>
          <Styled.ul>
            <Styled.li>
              <Text sx={{ fontFamily: 'secondary', fontSize: 2 }}>
                You can easily find that sumptuous and crowd pleasing chicken
                casserole you did last year?
              </Text>
            </Styled.li>

            <Styled.li>
              <Text sx={{ fontFamily: 'secondary', fontSize: 2 }}>
                You can re-create that ultimate chiffon cake masterpiece you
                made for you son's birthday five months ago?
              </Text>
            </Styled.li>

            <Styled.li>
              <Text sx={{ fontFamily: 'secondary', fontSize: 2 }}>
                You can create an entire menu for future special occasions and
                access them easily?
              </Text>
            </Styled.li>
          </Styled.ul>
        </Box>
        <Box
          mx={2}
          mt={4}
          sx={{ fontFamily: 'main', textAlign: 'justify', fontSize: 2 }}>
          Saving online links, wherever you do it, is a mess. Finding a specific
          recipe in food blogs which you obsessively follow can be very chaotic
          and frustrating. Chances are, you end up settling for another one you
          think could be as awesome but end up with a flat tasting bread, tossed
          in the trash. You searched for the best chocolate cake recipe, found
          one, baked one, and voila--- what a great success! Sweet tooths have
          definitely been satisfied. As you move on, have you ever tried
          scouring the web again for the same "perfect" recipe?
        </Box>
        <Box
          mx={2}
          mt={4}
          sx={{ fontFamily: 'main', textAlign: 'justify', fontSize: 2 }}>
          It would be nice to keep them in one portfolio like an old fashioned
          index card binder, a collection of grandma's recipes. This is pretty
          much the same,{' '}
          <Text as='span' sx={{ fontWeight: 600 }}>
            ONLY BETTER
          </Text>
          .
        </Box>
        <Box
          mx={2}
          mt={4}
          sx={{ fontFamily: 'main', textAlign: 'justify', fontSize: 2 }}>
          Wouldn’t you love to have all the recipes you want to make for this
          week's menu or your next wedding anniversary, in a searchable
          location, accessible from your computer, tablet or phone?
        </Box>
        <Box
          mx={2}
          mt={4}
          mb={4}
          sx={{
            fontFamily: 'secondary',
            textAlign: 'justify',
            fontWeight: '600',
            fontSize: 2,
          }}>
          It's time to organise. Your life may be in shambles but not your
          menus. Creating meal plans is easy peasy.
        </Box>
      </Box>
    </Section>
  );
};
