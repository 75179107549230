import React from 'react';
import { Box, Flex, Text } from 'rebass';

export const TagLine: React.FC<{ released: boolean }> = ({ released }) => (
  <Box
    sx={{
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: ['100%', '50%'],
    }}>
    <Text sx={{ fontSize: 5, fontWeight: '900', fontFamily: 'main' }}>
      Your
    </Text>
    <Text sx={{ fontSize: 5, fontWeight: '900', fontFamily: 'main' }}>
      favorite recipes
    </Text>
    <Text sx={{ fontSize: 5, fontWeight: '900', fontFamily: 'main' }}>
      at your fingertips!
    </Text>
    <Flex
      justifyContent='center'
      alignItems='center'
      flexDirection={['column', 'row']}
      mt={4}>
      {released && (
        <Flex
          justifyContent='center'
          alignItems='center'
          sx={{ width: ['100%', '50%'] }}>
          <a href='itms-apps://apps.apple.com/us/app/nam-nam-na/id1515710700?ls=1'>
            <img
              alt='Download on the App Store'
              src='./images/app-store-badge.png'
              style={{
                width: 'auto',
                height: '80px',
                maxHeight: '80px',
              }}
            />
          </a>
        </Flex>
      )}
      <Flex
        justifyContent='center'
        alignItems='center'
        sx={{ width: ['100%', '50%'] }}>
        <a href='https://play.google.com/store/apps/details?id=com.madokape.namnamna&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img
            alt='Download on the App Store'
            src='./images/google-play-badge.png'
            style={{
              width: 'auto',
              height: '80px',
              maxHeight: '80px',
            }}
          />
        </a>
      </Flex>
    </Flex>

    <Flex
      mt={3}
      alignItems='center'
      justifyContent='center'
      flexDirection='column'>
      <Text fontWeight='600'>Follow us on:</Text>
      <Flex flexDirection='row' mt={1}>
        <a href='https://www.facebook.com/namnamapp'>
          <img
            alt='Facebook link'
            src='./images/Facebook.svg'
            width='40px'
            height='40px'
            style={{ marginLeft: 15 }}
          />
        </a>
        <a href='https://www.instagram.com/namnamapp/'>
          <img
            alt='Instagram link'
            src='./images/Instagram.svg'
            width='40px'
            height='40px'
            style={{ marginLeft: 15, marginRight: 15 }}
          />
        </a>
      </Flex>
    </Flex>
  </Box>
);
