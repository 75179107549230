import React from 'react';
import { Box } from 'rebass';

import { AdvantagesSection } from './advantages';
import { ContactSection } from './contact';
import { FooterSection } from './footer';
import { HomeSection } from './home';
import { HowItWorksSection } from './how-it-works';
import { AppSection } from './the-app';

export const AppBody: React.FC<{
  closeMenu: () => void;
  released: boolean;
  setHeaderBackground: (background: 'fill' | 'none') => void;
}> = ({ closeMenu, setHeaderBackground, released }) => {
  return (
    <Box
      variant='body'
      onClick={closeMenu}
      onScroll={(e) => {
        e.currentTarget.scrollTop >= 36
          ? setHeaderBackground('fill')
          : setHeaderBackground('none');
      }}>
      <HomeSection released={released} />
      <AppSection />
      <HowItWorksSection />
      <AdvantagesSection />
      <ContactSection />
      <FooterSection />
    </Box>
  );
};
