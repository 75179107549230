// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Box } from 'rebass';
import { ThemeProvider } from 'theme-ui';

import { AppBody } from './components/body';
import { Header } from './components/header';
import { Sidebar } from './components/sidebar';
import { theme } from './theme/theme';

const MyRecipes = () => {
  const released = true;
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [headerBackground, setHeaderBackground] = React.useState<
    'fill' | 'none'
  >('none');

  const openMenu = () => {
    ReactGA.event({
      category: 'openMenu',
      action: 'open menu clicked',
    });
    setShowMenu(true);
  };
  const closeMenu = () => setShowMenu(false);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Nam Nam Na! by Madokape</title>
        <meta property='og:title' content='Nam Nam Na!' />
        <meta
          property='og:description'
          content='Keep your favorite recipes organized, create meal plans and more!'
        />
        <meta property='og:image' content={'./images/app-icon.png'} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      <Box variant='backgroundImage'>
        <Box variant='backgroundImageFilter' />
      </Box>
      <Header openMenu={openMenu} filled={headerBackground === 'fill'} />
      <Sidebar visible={showMenu} closeMenu={closeMenu} />
      <AppBody
        closeMenu={closeMenu}
        setHeaderBackground={setHeaderBackground}
        released={released}
      />
    </ThemeProvider>
  );
};

export default MyRecipes;
