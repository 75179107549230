// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Text } from 'rebass';
import { ThemeProvider } from 'theme-ui';

import { Section } from './components/body';
import { FooterSection } from './components/body/footer';
import PrivacyChanges from './components/privacy/changes';
import PrivacyContact from './components/privacy/contact';
import PrivacyDefinitions from './components/privacy/definitions';
import PrivacyDisclosure from './components/privacy/disclosure';
import PrivacyInformationCollection from './components/privacy/information-collection';
import PrivacyLinksToOther from './components/privacy/links';
import PrivacyPayments from './components/privacy/payments';
import PrivacyProcessing from './components/privacy/processing';
import PrivacyProviders from './components/privacy/providers';
import PrivacyRetention from './components/privacy/retention';
import PrivacyRights from './components/privacy/rights';
import PrivacySecurity from './components/privacy/security';
import PrivacyUseOfData from './components/privacy/use-of-data';
import { theme } from './theme/theme';

const MyRecipesPrivacy = () => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Nam Nam Na! by Madokape</title>
        <meta property='og:title' content='Nam Nam Na!' />
        <meta
          property='og:description'
          content='Keep your favorite recipes organized, create meal plans and more!'
        />
        <meta property='og:image' content={'./images/app-icon.png'} />
        <meta name='twitter:card' content='summary_large_image' />
      </Helmet>
      <Box variant='backgroundImage'>
        <Box variant='backgroundImageFilter' />
      </Box>
      {/* <Header openMenu={openMenu} filled={headerBackground === 'fill'} /> */}
      {/* <Sidebar visible={showMenu} closeMenu={closeMenu} /> */}
      <Section id='home-anchor'>
        <Box
          sx={{
            color: '#FFFFFF',
          }}
          pb={4}>
          <Text
            m={4}
            sx={{
              fontSize: '6',
              textAlign: 'center',
              color: '#FFFFFF',
            }}>
            Nam Nam Na! - Privacy Policy
          </Text>
          <Box>
            <Text textAlign='right' fontFamily='secondary' fontSize={1}>
              Effective date: May 28, 2020
            </Text>
          </Box>
        </Box>
      </Section>
      <Section background='#FFFFFF' id='pp' sx={{ fontFamily: 'secondary' }}>
        <Box>
          <Text
            my={5}
            sx={{ fontSize: 5, textAlign: 'center', fontWeight: '900' }}>
            How do we process your personal data?
          </Text>
        </Box>
        <Box my={4} sx={{ textAlign: 'justify', fontSize: '1' }}>
          <Text mb={4}>
            Jonathan Jouret ("us", "we", or "our") operates the{' '}
            <a
              href='http;//www.madokape.com'
              target='_blank'
              rel='noopener noreferrer'>
              madokape.com
            </a>{' '}
            website and the{' '}
            <Text as='span' sx={{ whiteSpace: 'nowrap' }}>
              “Nam Nam Na!”
            </Text>{' '}
            mobile application (the "Service"). This page informs you of our
            policies regarding the collection, use, and disclosure of personal
            data when you use our Service and the choices you have associated
            with that data.
          </Text>
          <PrivacyDefinitions />
          <PrivacyInformationCollection />
          <PrivacyUseOfData />
          <PrivacyProcessing />
          <PrivacyRetention />
          <PrivacyDisclosure />
          <PrivacySecurity />
          <PrivacyRights />
          <PrivacyProviders />
          <PrivacyPayments />
          <PrivacyLinksToOther />
          <PrivacyChanges />
          <PrivacyContact />
        </Box>
      </Section>
      <FooterSection />
    </ThemeProvider>
  );
};

export default MyRecipesPrivacy;
