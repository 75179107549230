import React from 'react';
import { Box } from 'rebass';

export const Devices = () => (
  <Box sx={{ position: 'relative', margin: '0 auto', marginTop: 4 }}>
    <Box
      sx={{
        width: '305px',
        height: '350px',
        background: `url(./images/iPad.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
      }}></Box>
    <Box
      sx={{
        width: '300px',
        height: '300px',
        background: `url(./images/iPhone.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        bottom: '-75px',
        left: '-75px',
      }}></Box>
  </Box>
);
