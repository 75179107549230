// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyRetention = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Retention of Data
      </Text>
      <Text>
        We will retain your Data only for as long as is necessary for the
        purposes set out in this Privacy Policy. We will retain and use your
        Data to the extent necessary to comply with our legal obligations (for
        example, if we are required to retain your data to comply with
        applicable laws), resolve disputes, and enforce our legal agreements and
        policies. We will delete your personal data 1 year after the last
        connection to our service.
        <br /> We will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of our Service, or we are legally obligated to retain this
        data for longer time periods.
      </Text>
    </>
  );
};

export default PrivacyRetention;
