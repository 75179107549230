// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyChanges = () => {
  return (
    <>
      <Text
        mt='1em'
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Changes To This Privacy Policy
      </Text>
      <Text>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
        <br /> We will let you know via email and/or a prominent notice on our
        Service, prior to the change becoming effective and update the
        "effective date" at the top of this Privacy Policy. <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </Text>
    </>
  );
};

export default PrivacyChanges;
