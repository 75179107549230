export const theme = {
  breakpoints: ['480px', '768px', '992px', '1200px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    main: '"Open Sans", sans-serif',
    secondary: '"Montserrat", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    normal: 400,
    bold: 700,
    bolder: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96],
  colors: {
    text: '#222B45',
    white: '#fff',
    primary: '#F57C00',
    secondary: '#30c',
    muted: '#C5CEE0',
    'color-primary-500': '#F57C00',
    'color-basic-100': '#FFFFFF',
    'color-basic-200': '#F7F9FC',
    'color-basic-300': '#EDF1F7',
    'color-basic-400': '#E4E9F2',
    'color-basic-500': '#C5CEE0',
    'color-basic-600': '#8F9BB3',
    'color-basic-700': '#2E3A59',
    'color-basic-800': '#222B45',
    'color-basic-900': '#1A2138',
    'color-basic-1000': '#151A30',
    'color-basic-1100': '#101426',
  },
  variants: {
    backgroundImage: {
      background: "url('./images/background.jpg')",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -2,
    },
    backgroundImageFilter: {
      background: 'rgba(0,0,0,.4)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
    },
    header: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '55px',
    },
    body: {
      position: 'fixed',
      top: '55px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      overflow: 'scroll',
    },
    section: {
      width: '100%',
    },
    pageWidth: {
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      padding: 2,
    },
    homeBox: {
      display: 'flex',
      flexDirection: ['column', 'row'],
      color: '#ffffff',
      marginTop: 3,
    },
    privacyHeader: {
      fontSize: '6',
      textAlign: 'center',
      color: 'color-primary-500',
    },
  },
  styles: {
    root: {
      fontFamily: 'main',
      lineHeight: 'body',
      fontWeight: 'normal',
      background: '#000',
    },
    h1: {
      color: 'text',
      fontFamily: 'main',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: 5,
      margin: 0,
    },
    h2: {
      color: 'text',
      fontFamily: 'main',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: 4,
      margin: 0,
    },
    h3: {
      color: 'text',
      fontFamily: 'main',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: 3,
      margin: 0,
    },
    h4: {
      fontFamily: 'main',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: 2,
      margin: 0,
    },
    h5: {
      color: 'text',
      fontFamily: 'main',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: 1,
      margin: 0,
    },
    h6: {
      color: 'text',
      fontFamily: 'main',
      lineHeight: 'heading',
      fontWeight: 'bold',
      fontSize: 0,
      margin: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'main',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'secondary',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'secondary',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
    ul: {
      marginTop: 3,
      paddingRight: 3,
      listStyleType: 'circle',
    },
    li: {
      textAlign: 'justify',
      marginTop: 2,
    },
  },
};
