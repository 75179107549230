import './App.css';

import React from 'react';
import ReactGA from 'react-ga';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import MyRecipes from './my-recipes/my-recipe.app';
import MyRecipesPrivacy from './my-recipes/my-recipes-privacy';

ReactGA.initialize('UA-169225421-1');
ReactGA.pageview(
  window.location.pathname + window.location.hash + window.location.search
);

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path='/nam-nam-na/privacy'>
          <MyRecipesPrivacy />
        </Route>
        <Route path='/nam-nam-na'>
          <MyRecipes />
        </Route>
        <Route path='/'>
          <Redirect to='/nam-nam-na' />
        </Route>
      </Switch>
      {/* <div className='App'>
        <header className='App-header'>
          <p>Something great is coming your way...</p>
        </header>
      </div> */}
    </Router>
  );
};

export default App;
