import React from 'react';
import { Flex, Link, Text } from 'rebass';

import { Section } from '../section';

export const ContactSection = () => {
  return (
    <Section id='' background='color-basic-400'>
      <Text
        id='contact'
        mt={4}
        sx={{
          color: 'color-primary-500',
          textAlign: 'center',
          fontSize: 5,
          fontWeight: 600,
        }}>
        Still have questions?
      </Text>
      <Flex flexDirection='column' alignItems='center' mb={4}>
        <Text mx={4}>
          Drop us an email and we will get back to you as soon as possible.
        </Text>
        <Link
          sx={{
            appearance: 'none',
            display: 'inline-block',
            textAlign: 'center',
            textDecoration: 'none',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '8px',
            paddingBottom: '8px',
            color: '#fff',
            backgroundColor: '#F57C00',
            border: 0,
            borderRadius: '4px',
          }}
          mt={4}
          href='mailto:support@madokape.com'
          target='_blank'
          rel='noopener noreferrer'>
          Send us an email
        </Link>
      </Flex>
    </Section>
  );
};
