// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacySecurity = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Security Of Data
      </Text>
      <Text>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data.
      </Text>
    </>
  );
};

export default PrivacySecurity;
