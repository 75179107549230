// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Box, Text } from 'rebass';

const PrivacyProviders = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Service Providers
      </Text>
      <Text>
        We will employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. <br />
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </Text>
      <Text
        py={2}
        sx={{
          fontWeight: '600',
        }}>
        Analytics
      </Text>
      <Text>
        We will use third-party Service Providers to monitor and analyze the use
        of our Service.
      </Text>
      <ul>
        <li>
          <Box pb={2}>
            <Text pb={2}>Google Analytics</Text>
            <Text>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualize and personalize the ads of its own advertising
              network. <br />
              For more information on the privacy practices of Google, please
              visit the Google Privacy & Terms web page:
              <a
                href='http://www.google.com/intl/en/policies/privacy/'
                target='_blank'
                rel='noopener noreferrer'>
                http://www.google.com/intl/en/policies/privacy/
              </a>
            </Text>
          </Box>
        </li>
      </ul>
    </>
  );
};

export default PrivacyProviders;
