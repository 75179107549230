import React from 'react';
import ReactGA from 'react-ga';
import { Box, Text } from 'rebass';
import { Styled } from 'theme-ui';

export const Menu: React.FC<{ filled: boolean }> = ({ filled }) => {
  return (
    <Box
      sx={{
        display: ['none', 'none', 'flex'],
        flexGrow: 1,
        flexBasis: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
      <MenuElement filled={filled} anchor='home-anchor'>
        Home
      </MenuElement>
      <MenuSeparator />
      <MenuElement filled={filled} anchor='app-anchor'>
        The App
      </MenuElement>
      <MenuSeparator />
      <MenuElement filled={filled} anchor='how-it-works'>
        How it works
      </MenuElement>
      <MenuSeparator />
      <MenuElement filled={filled} anchor='advantages'>
        Advantages
      </MenuElement>
      <MenuSeparator />
      <MenuElement filled={filled} anchor='contact'>
        Contact
      </MenuElement>
    </Box>
  );
};

const MenuElement: React.FC<{
  filled: boolean;
  anchor: string;
  children: React.ReactNode;
}> = ({ filled, anchor, children }) => {
  return (
    <Styled.h4
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        ReactGA.event({
          category: 'menuClick',
          action: anchor,
        });
        document.getElementById(anchor)?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }}>
      <Text
        sx={{
          color: filled ? 'text' : '#FFFFFF',
          cursor: 'pointer',
        }}>
        {children}
      </Text>
    </Styled.h4>
  );
};

const MenuSeparator = () => <Box mx={3}></Box>;
