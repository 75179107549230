import React from 'react';
import { Box, Flex, Text } from 'rebass';

import { Section } from '../section';
import { Devices } from './devices';

export const AdvantagesSection = () => {
  return (
    <Section id='' background='#FFFFFF'>
      <Text
        id='advantages'
        mt={4}
        sx={{
          color: 'color-primary-500',
          textAlign: 'center',
          fontSize: 5,
          fontWeight: 600,
        }}>
        Why use{' '}
        <Text as='span' sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
          "Nam Nam Na!"
        </Text>
        ?
      </Text>
      <Flex flexDirection={['column', 'row']}>
        <Flex flex={1}>
          <Devices />
        </Flex>
        <Flex flex={1} mb={4} flexDirection='column'>
          <Advantage title='Organised'>
            <Text>
              Organise your recipes in easy to access categories and add tags
              for easy searching. Rate them for future reference.
              {/* {' '}
              <Text as='span' fontWeight='600'>
                Never settle for a bad recipe again
              </Text>
              . */}
            </Text>
          </Advantage>
          <Advantage title='Create menus'>
            <Text>
              Create full menus for your special events so you won't have to
              search for the recipes again.
            </Text>
          </Advantage>
          <Advantage title='Add your personal touch'>
            <Text>Write notes to personalise recipes.</Text>
          </Advantage>
        </Flex>
      </Flex>
    </Section>
  );
};

const Advantage: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <Flex flexDirection='column' my={3} mx={2}>
    <Box>
      <Text fontSize={3} fontWeight='600'>
        {title}
      </Text>
    </Box>
    <Box mt={0}>{children}</Box>
  </Flex>
);
