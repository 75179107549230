import React from 'react';
import ReactGA from 'react-ga';
import { Box, Text } from 'rebass';

export const Sidebar: React.FC<{ visible: boolean; closeMenu: () => void }> = ({
  visible,
  closeMenu,
}) => {
  const goToAnchor = (anchor: string) => {
    ReactGA.event({
      category: 'sidebarClick',
      action: anchor,
    });
    closeMenu();
    document.getElementById(anchor)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: 999,
        width: '250px',
        background: '#FFFFFF',
        transition: 'left 400ms cubic-bezier(0.15, 0.8, 0.3, 1.01)',
        padding: 3,
        boxShadow: '0 0 4px rgba(0, 0, 0, .175)',
      }}
      css={{
        left: visible ? 0 : -250,
      }}>
      <Text as='h2' sx={{ color: 'color-primary-500' }}>
        Nam Nam Na!
      </Text>
      <SidebarItem onPress={() => goToAnchor('home-anchor')}>Home</SidebarItem>
      <SidebarItem onPress={() => goToAnchor('app-anchor')}>
        The App
      </SidebarItem>
      <SidebarItem onPress={() => goToAnchor('how-it-works')}>
        How it works
      </SidebarItem>
      <SidebarItem onPress={() => goToAnchor('advantages')}>
        Advantages
      </SidebarItem>
      <SidebarItem onPress={() => goToAnchor('contact')}>Contact</SidebarItem>
    </Box>
  );
};

const SidebarItem: React.FC<{
  onPress: () => void;
  children: React.ReactNode;
}> = ({ onPress, children }) => (
  <Text
    as='h4'
    sx={{ marginY: 3, '&:hover': { color: 'color-primary-500' } }}
    onClick={onPress}>
    {children}
  </Text>
);
