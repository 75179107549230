import React from 'react';
import { Box, BoxProps } from 'rebass';

import { Menu } from './menu';
import { MenuIcon } from './menu-icon';

export const Header: React.FC<
  {
    openMenu: () => void;
    filled: boolean;
  } & BoxProps
> = ({ openMenu, filled }) => (
  <Box
    variant='header'
    sx={{
      background: filled ? '#FFFFFF' : 'transparent',
      boxShadow: filled ? '0 0 4px rgba(0, 0, 0, .175)' : '',
    }}>
    <Box
      variant='pageWidth'
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
      }}>
      <MenuIcon openMenu={openMenu} filled={filled} />
      <Box sx={{ minWidth: 'fit-content' }}>
        <Box
          as='h3'
          sx={{
            color: filled ? 'text' : '#FFFFFF',
            fontSize: '26px',
          }}>
          Nam Nam Na!
        </Box>
      </Box>
      <Menu filled={filled} />
    </Box>
  </Box>
);
