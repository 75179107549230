// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyContact = () => {
  return (
    <>
      <Text
        mt='1em'
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Contact Us
      </Text>
      <Text pb='1em'>
        If you have any questions about this Privacy Policy, please contact us
        by email at{' '}
        <a
          href='mailto:support@madokape.com'
          target='_blank'
          rel='noopener noreferrer'>
          support@madokape.com
        </a>
      </Text>
    </>
  );
};

export default PrivacyContact;
