import React from 'react';
import { Box } from 'rebass';

import { Section } from '../section';
import { Devices } from './devices';
import { TagLine } from './tag-line';

export const HomeSection: React.FC<{ released: boolean }> = ({ released }) => {
  return (
    <Section id='home-anchor'>
      <Box variant='homeBox'>
        <TagLine released={released} />
        <Devices />
      </Box>
    </Section>
  );
};
