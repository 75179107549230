import React from 'react';
import { Box } from 'rebass';

export const MenuIcon: React.FC<{
  openMenu: () => void;
  filled: boolean;
}> = ({ openMenu, filled }) => {
  return (
    <Box
      sx={{
        display: ['inherit', 'inherit', 'none'],
        flexGrow: 1,
        flexBasis: '100%',
        alignItems: 'center',
      }}>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          openMenu();
        }}
        sx={{
          background: filled
            ? 'url("./images/menu-dark.svg")'
            : 'url("./images/menu.svg")',
          width: '32px',
          height: '32px',
        }}></Box>
    </Box>
  );
};
