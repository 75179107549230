// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyUseOfData = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Use of Data
      </Text>
      <Text>We uses the collected data for various purposes:</Text>
      <ul>
        <li>
          <Text>To provide and maintain our Service</Text>
        </li>
        <li>
          <Text>To provide customer support</Text>
        </li>
        <li>
          <Text>
            To gather analysis or valuable information so that we can improve
            our Service
          </Text>
        </li>
        <li>
          <Text>To monitor the usage of our Service</Text>
        </li>
        <li>
          <Text>To detect, prevent and address technical issues</Text>
        </li>
      </ul>
    </>
  );
};

export default PrivacyUseOfData;
