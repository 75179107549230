// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyDisclosure = () => {
  return (
    <>
      <Text
        mt='1em'
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Disclosure Of Data
      </Text>
      <Text>
        In principle, your personal data won’t be disclosed to third parties.
        However, this might happen in two cases: a business transaction or a
        legal requirement.
      </Text>
      <Text
        py={2}
        sx={{
          fontWeight: '600',
        }}>
        Business Transaction
      </Text>
      <Text>
        If we are involved in a merger, acquisition or asset sale, your Data may
        be transferred.
      </Text>
      <Text
        py={2}
        sx={{
          fontWeight: '600',
        }}>
        Legal Requirements
      </Text>
      <Text>
        We may disclose your Data in the good faith belief that such action is
        necessary:
      </Text>
      <ul>
        <li>
          <Text>To comply with a legal obligation</Text>
        </li>
        <li>
          <Text>
            To protect and defend the rights or property of Jonathan Jouret
          </Text>
        </li>
        <li>
          <Text>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </Text>
        </li>
        <li>
          <Text>
            To protect the personal safety of users of the Service or the public
          </Text>
        </li>
        <li>
          <Text>To protect against legal liability</Text>
        </li>
      </ul>
    </>
  );
};

export default PrivacyDisclosure;
