// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyProcessing = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Legal Basis for Processing Personal Data Under General Data Protection
        Regulation (GDPR)
      </Text>
      <Text>
        If you are from the European Economic Area (EEA), our legal basis for
        processing your personal data described in this Privacy Policy depends
        on the Personal Data we collect and the specific context in which we
        collect it.
      </Text>
      <Text>“Nam Nam Na!” will process your Personal Data because:</Text>
      <ul>
        <li>
          <Text>You have given us permission to do so</Text>
        </li>
        <li>
          <Text>
            The processing is in our legitimate interests, part of a product
            features, and it's not overridden by your rights
          </Text>
        </li>
        <li>
          <Text>To comply with the law</Text>
        </li>
      </ul>
    </>
  );
};

export default PrivacyProcessing;
