import React from 'react';
import { Box } from 'rebass';

export const Section: React.FC<{
  id: string;
  background?: string;
  children: React.ReactNode;
}> = ({ id, background, children }) => (
  <Box
    id={id}
    variant='section'
    sx={{
      backgroundColor: background,
    }}>
    <Box variant='pageWidth'>{children}</Box>
  </Box>
);
