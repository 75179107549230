import React from 'react';
import { Flex, Text } from 'rebass';

import { Section } from '../section';

export const FooterSection = () => {
  return (
    <Section id='footer' background='color-basic-1000'>
      <Flex flexDirection={['column-reverse', 'row']} flex={1} my={4}>
        <Flex flexDirection={['column']}>
          <Text
            color='color-basic-200'
            sx={{ flex: 1, textAlign: ['center', 'left'] }}>
            &copy; 2020 - Nam Nam Na! by Madokape
          </Text>
          <Text
            mt={2}
            fontSize='10px'
            color='color-basic-200'
            sx={{ flex: 1, textAlign: ['center', 'left'] }}>
            App Store® and Apple logo® are trademarks of Apple Inc.
          </Text>
          <Text
            fontSize='10px'
            color='color-basic-200'
            sx={{ flex: 1, textAlign: ['center', 'left'] }}>
            Google Play and the Google Play logo are trademarks of Google LLC.
          </Text>
        </Flex>
        <Text
          color='color-basic-200'
          sx={{
            flex: 1,
            textAlign: ['center', 'right'],
            marginBottom: [2, 0],
          }}>
          <a
            href='http://madokape.com/#/nam-nam-na/privacy'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#F7F9FC',
              textDecoration: 'none',
            }}>
            Privacy Policy
          </a>
        </Text>
      </Flex>
    </Section>
  );
};
