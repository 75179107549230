// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Box, Text } from 'rebass';

const PrivacyPayments = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Payments
      </Text>
      <Text>
        We may provide paid products and/or services within the Service. In that
        case, we use third-party services for payment processing (e.g. payment
        processors). We will not store or collect your payment card details.
        That information is provided directly to our third-party payment
        processors whose use of your personal information is governed by their
        Privacy Policy. These payment processors adhere to the standards set by
        PCI-DSS as managed by the PCI Security Standards Council, which is a
        joint effort of brands like Visa, Mastercard, American Express and
        Discover. PCI-DSS requirements help ensure the secure handling of
        payment information. The payment processors we work with are:
      </Text>
      <ul>
        <li>
          <Box pb={2}>
            <Text pb={2}>Apple Store In-App Payments</Text>
            <Text>
              Their Privacy Policy can be viewed at{' '}
              <a
                href='https://www.apple.com/legal/privacy/en-ww/'
                target='_blank'
                rel='noopener noreferrer'>
                https://www.apple.com/legal/privacy/en-ww/
              </a>
            </Text>
          </Box>
        </li>
        <li>
          <Box pb={2}>
            <Text pb={2}>Google Play In-App Payments</Text>
            <Text>
              Their Privacy Policy can be viewed at{' '}
              <a
                href='https://www.google.com/policies/privacy/'
                target='_blank'
                rel='noopener noreferrer'>
                https://www.google.com/policies/privacy/
              </a>
            </Text>
          </Box>
        </li>
      </ul>
    </>
  );
};

export default PrivacyPayments;
