import React from 'react';
import { Box } from 'rebass';

export const Devices = () => (
  <Box sx={{ position: 'relative', margin: '0 auto', marginTop: 5 }}>
    <Box
      sx={{
        width: '300px',
        height: '300px',
        background: `url(./images/iPhone-recipe.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        position: 'relative',
        right: '25px',
      }}></Box>
    <Box
      sx={{
        width: '300px',
        height: '300px',
        background: `url(./images/iPhone-events.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'absolute',
        top: '-25px',
        left: '-55px',
      }}></Box>
  </Box>
);
