// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyRights = () => {
  return (
    <>
      <Text
        mt='1em'
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Your Data Protection Rights Under General Data Protection Regulation
        (GDPR)
      </Text>
      <Text>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. We aim to take reasonable steps to allow
        you to correct, amend, delete, or limit the use of your Personal Data.
        <br />
        If you wish to be informed if Personal Data about you is held by us and
        if you want it to be removed from our systems, please contact{' '}
        <a
          href='mailto:support@madokape.com'
          target='_blank'
          rel='noopener noreferrer'>
          support@madokape.com
        </a>
        . <br />
        In certain circumstances, you have the following data protection rights:
      </Text>
      <ul>
        <li>
          <Text>
            <Text as='span' sx={{ fontWeight: '600' }}>
              The right to access, update or to delete the information we have
              on you.
            </Text>{' '}
            Whenever made possible, you can access, update or request deletion
            of your Personal Data by contacting us.
          </Text>
        </li>
        <li>
          <Text>
            <Text as='span' sx={{ fontWeight: '600' }}>
              The right of rectification.
            </Text>{' '}
            You have the right to have your information rectified if that
            information is inaccurate or incomplete.
          </Text>
        </li>
        <li>
          <Text>
            <Text as='span' sx={{ fontWeight: '600' }}>
              The right to object.
            </Text>{' '}
            You have the right to object to our processing of your Personal
            Data.
          </Text>
        </li>
        <li>
          <Text>
            <Text as='span' sx={{ fontWeight: '600' }}>
              The right of restriction.
            </Text>{' '}
            You have the right to request that we restrict the processing of
            your personal information.
          </Text>
        </li>
        <li>
          <Text>
            <Text as='span' sx={{ fontWeight: '600' }}>
              The right to data portability.
            </Text>{' '}
            You have the right to be provided with a copy of the information we
            have on you in a structured, machine-readable and commonly used
            format.
          </Text>
        </li>
        <li>
          <Text>
            <Text as='span' sx={{ fontWeight: '600' }}>
              The right to withdraw consent.
            </Text>{' '}
            You also have the right to withdraw your consent at any time where
            we relied on your consent to process your personal information.
          </Text>
        </li>
      </ul>
      <Text pb={3}>
        Please note that we may ask you to verify your identity before
        responding to such requests. You have the right to complain to a Data
        Protection Authority about our collection and use of your Personal Data.
        For more information, please contact your local data protection
        authority in the European Economic Area (EEA).
      </Text>
    </>
  );
};

export default PrivacyRights;
