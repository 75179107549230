// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Box, Text } from 'rebass';

const PrivacyDefinitions = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Definitions
      </Text>
      <ul>
        <li>
          <Box pb={2}>
            <Text
              sx={{
                fontWeight: '700',
              }}>
              Service
            </Text>
            <Text>
              Service means the madokape.com website and the “Nam Nam Na!” app
              operated by Jonathan Jouret
            </Text>
          </Box>
        </li>
        <li>
          <Box pb={2}>
            <Text
              sx={{
                fontWeight: '700',
              }}>
              Personal Data
            </Text>
            <Text>
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </Text>
          </Box>
        </li>
        <li>
          <Box pb={2}>
            <Text
              sx={{
                fontWeight: '700',
              }}>
              Usage Data
            </Text>
            <Text>
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the requests made by the app).
            </Text>
          </Box>
        </li>
        <li>
          <Box pb={2}>
            <Text
              sx={{
                fontWeight: '700',
              }}>
              Data Controller
            </Text>
            <Text>
              Data Controller means the natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal
              information are, or are to be, processed.
              <br />
              For the purpose of this Privacy Policy, we are a Data Controller
              of your Personal Data.
            </Text>
          </Box>
        </li>
        <li>
          <Box pb={2}>
            <Text
              sx={{
                fontWeight: '700',
              }}>
              Data Processors (or Service Providers)
            </Text>
            <Text>
              Data Processor (or Service Provider) means any natural or legal
              person who processes the data on behalf of the Data Controller.
              <br /> We may use the services of various Service Providers in
              order to process your data more effectively.
            </Text>
          </Box>
        </li>
        <li>
          <Box pb={2}>
            <Text
              sx={{
                fontWeight: '700',
              }}>
              Data Subject (or User)
            </Text>
            <Text>
              Data Subject is any living individual who is using our Service and
              is the subject of Personal Data.
            </Text>
          </Box>
        </li>
      </ul>
    </>
  );
};

export default PrivacyDefinitions;
