// import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Text } from 'rebass';

const PrivacyInformationCollection = () => {
  return (
    <>
      <Text
        sx={{
          fontSize: 4,
          fontWeight: '700',
        }}>
        Information Collection And Use
      </Text>
      <Text pb={3}>
        We collect different types of personal data for various purposes to
        provide and improve our Service to you.
      </Text>
      <Text
        pb={3}
        sx={{
          fontWeight: '700',
        }}>
        Types of Data Collected
      </Text>
      <Text
        pb={2}
        sx={{
          fontWeight: '600',
        }}>
        Personal Data
      </Text>
      <Text>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that could be used to identify you
        ("Personal Data"). Personal data includes:
      </Text>
      <ul>
        <li>
          <Text>First name, last name and email address</Text>
        </li>
        <li>
          <Text>Usage Data</Text>
        </li>
      </ul>
      <Text
        pb={2}
        sx={{
          fontWeight: '600',
        }}>
        Usage Data
      </Text>
      <Text pb={3}>
        We may also collect information that your browser sends whenever you
        visit our Service or when you access the Service by or through a mobile
        device ("Usage Data").
        <br /> This Usage Data may include information such as your computer's
        Internet Protocol address (e.g. IP address), browser type, browser
        version, the pages of our Service that you visit, the time and date of
        your visit, the time spent on those pages, unique device identifiers and
        other diagnostic data. When you access the Service by or through a
        mobile device, this Usage Data may include information such as the type
        of mobile device you use, your mobile device unique ID, the IP address
        of your mobile device, your mobile operating system, the type of mobile
        Internet browser you use, unique device identifiers and other diagnostic
        data.
      </Text>
      <Text
        pb={2}
        sx={{
          fontWeight: '600',
        }}>
        Tracking
      </Text>
      <Text pb={3}>
        We use different tracking technologies to track the activity on our
        Service and hold certain information.
        <br /> Tracking technologies also used are beacons, tags, and scripts to
        collect and track information and to improve and analyze our Service.
        Those tracking data are anonymized and do not contain any of the User’s
        Personal Data.
      </Text>
    </>
  );
};

export default PrivacyInformationCollection;
