import React from 'react';
import { Flex, Text } from 'rebass';

import { Section } from '../section';

export const HowItWorksSection = () => {
  return (
    <Section id='howSection' background='color-basic-500'>
      <Text
        id='how-it-works'
        mt={4}
        sx={{
          color: 'color-primary-500',
          textAlign: 'center',
          fontSize: 5,
          fontWeight: 600,
        }}>
        Save your recipes in 3 simple steps
      </Text>
      <Flex flexDirection={['column', 'row']} mt={2} mb={4}>
        <Item>
          <Content
            image='link'
            title='Copy the URL'
            description='Go to the website of your favorite recipe and copy the link'
          />
        </Item>
        <Item>
          <Content
            image='download'
            title='Save in the app'
            description='Add the new recipe by pasting the link'
          />
        </Item>
        <Item>
          <Content
            image='edit-3'
            title='Customise'
            description='Edit the name or description, choose a category, ...'
          />
        </Item>
      </Flex>
    </Section>
  );
};

const Item: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Flex
    flexDirection='column'
    justifyContent='flex-start'
    alignItems='center'
    width='200px'
    margin='0 auto'
    backgroundColor='color-basic-500'
    my={3}
    sx={{
      borderRadius: '24px',
    }}>
    {children}
  </Flex>
);

const Content: React.FC<{
  image: string;
  title: string;
  description: string;
}> = ({ image, title, description }) => (
  <>
    <Flex
      sx={{
        background: `url("./images/${image}.svg")`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '44px',
        height: '44px',
        mt: 2,
      }}></Flex>
    <Flex mt={2} px={2}>
      <Text fontSize={3} fontWeight='600'>
        {title}
      </Text>
    </Flex>
    <Flex px={2} mt={2} mb={2}>
      <Text fontSize={2} color='color-basic-700' textAlign='center'>
        {description}
      </Text>
    </Flex>
  </>
);
